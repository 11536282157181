#wrapper {
  display: flex;
}

#left {
  background-color: bisque;
  flex: 0 0 50%;
  height: 50vh;
}

#right {
  background-color: #00f;
  flex: 1;
  height: 50vh;
}

#chart-container {
  border: 2px;
  flex: 0 0 90%;
  height: 50vh;
}

#controls {
  flex: 1;
}
/*# sourceMappingURL=index.1acb1cb8.css.map */
